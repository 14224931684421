import { createSlice } from "@reduxjs/toolkit"

export const productSlice = createSlice({
  name: "featureProducts",
  initialState: {
    productList: [], 
    brandList: [],
    normalProductList: [],
    exportList: [],
    productDetail: {},
    isFreshData: false,
    totalEntry: 0,
  },
  reducers: {
    setProductList: (state, action) => {
      state.productList = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
  },
    setBrandList: (state, action) => {
      state.brandList = action.payload
    },
    setNormalProductList: (state, action) => {
      state.normalProductList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.productDetail = action.payload
    }
  }
})

export const {
  setProductList,
  setFreshList,
  setBrandList,
  setNormalProductList,
  setExportList,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = productSlice.actions

export const selectProductList = (state) => state.featureProducts.productList
export const selectFreshData = (state) => state.featureProducts.isFreshData
export const selectBrandList = (state) => state.featureProducts.brandList
export const selectNormalProductList = (state) => state.featureProducts.normalProductList
export const selectExportList = (state) => state.featureProducts.exportList
export const selectTotalEntry = (state) => state.featureProducts.totalEntry
export const selectProductDetail = (state) => state.featureProducts.productDetail

export default productSlice.reducer
