import { createSlice } from "@reduxjs/toolkit"

export const contentSlice = createSlice({
    name: "content",
    initialState: {
        contentList: [],
        contentDetail: {},
        isFreshData: false,
        totalEntry: 0,
    },
    reducers: {
        setContentList: (state, action) => {
            state.contentList = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.contentDetail = action.payload
        }
    }
})

export const {
    setContentList,
    setSingleData,
    setTotalEntry,
} = contentSlice.actions

export const selectContentList = (state) => state.contents.contentList
export const selectTotalEntry = (state) => state.contents.totalEntry
export const selectDetail = (state) => state.contents.contentDetail

export default contentSlice.reducer
