import { createSlice } from "@reduxjs/toolkit"

export const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        notificationList: [],
        isFreshData: false,
        totalEntry: 0,
    },
    reducers: {
        setNotificationList: (state, action) => {
            state.notificationList = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
    }
}) 

export const {
    setNotificationList,
    setTotalEntry,
} = notificationSlice.actions

export const selectNotificationList = (state) => state.notification.notificationList
export const selectTotalEntry = (state) => state.notification.totalEntry

export default notificationSlice.reducer
