import { createSlice } from "@reduxjs/toolkit"

export const brandSlice = createSlice({
  name: "brand",
  initialState: {
    brandList: [],
    exportList: [],
    brandDetail: {},
    isFreshData: false,
    totalEntry: 0,
    filterTerm: ''
  },
  reducers: {
    setBrandList: (state, action) => {
      state.brandList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.brandDetail = action.payload
    }
  }
})

export const {
  setBrandList,
  setExportList,
  setFreshList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = brandSlice.actions

export const selectBrandList = (state) => state.brand.brandList
export const selectExportList = (state) => state.brand.exportList
export const selectFilterTerm = (state) => state.brand.filterTerm
export const selectTotalEntry = (state) => state.brand.totalEntry
export const selectFreshData = (state) => state.brand.isFreshData
export const selectBrandDetail = (state) => state.brand.brandDetail

export default brandSlice.reducer
