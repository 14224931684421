import { createSlice } from "@reduxjs/toolkit"

export const bankSlice = createSlice({
    name: "bank",
    initialState: {
        bankList: [], 
        bankDetail: {},
        isFreshData: false,
        totalEntry: 0,
    }, 
    reducers: { 
        setBankList: (state, action) => {
            state.bankList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.bankDetail = action.payload
        }
    }
})

export const {
    setBankList,
    setFreshList,
    setSingleData,
    setTotalEntry,
} = bankSlice.actions

export const selectBankList = (state) => state.bank.bankList
export const selectFreshData = (state) => state.bank.isFreshData
export const selectTotalEntry = (state) => state.bank.totalEntry
export const selectBankDetail = (state) => state.bank.bankDetail

export default bankSlice.reducer
