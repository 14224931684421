import { createSlice } from "@reduxjs/toolkit"

export const raffleSlice = createSlice({
  name: "raffle",
  initialState: {
    raffleList: [],
    productList: [],
    sizeList: [],
    quantitySize: [],
    raffleDetail: {},
    isFreshData: false,
    totalEntry: 0,
  },

  reducers: {
    setRaffleList: (state, action) => {
      state.raffleList = action.payload
    },
    setProductList: (state, action) => {
      state.productList = action.payload
    },
    setSizeList: (state, action) => {
      state.sizeList = action.payload
    },
    setQuantitySizeList: (state, action) => {
      state.quantitySize = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.raffleDetail = action.payload
    }
  }
})

export const {
  setRaffleList,
  setProductList,
  setSizeList,
  setQuantitySizeList,
  setSingleData,
  setTotalEntry,
} = raffleSlice.actions

export const selectRaffleList = (state) => state.raffle.raffleList
export const selectProductList = (state) => state.raffle.productList
export const selectSizeList = (state) => state.raffle.sizeList
export const selectQuantitySizeList = (state) => state.raffle.quantitySize
export const selectTotalEntry = (state) => state.raffle.totalEntry
export const selectRaffleDetail = (state) => state.raffle.raffleDetail

export default raffleSlice.reducer
