// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import auth from './slices/auth.Slice'
import users from './slices/usersSlice'
import brand from './slices/brandSlice'
import banner from './slices/bannerSlice'
import category from './slices/categorySlice'
import size from './slices/sizeSlice'
import products from './slices/productsSlice'
import calenderProduct from './slices/calenderProductsSlice'
import featureProducts from './slices/featureProductsSlice'
import orders from './slices/ordersSlice'
import setting from './slices/settingSlice'
import news from './slices/newsSlice'
import raffle from './slices/raffleSlice'
import voucher from './slices/voucherSlice'
import contents from './slices/contentsSlice'
import contact from './slices/contactSlice'
import wallet from './slices/walletSlice'
import transaction from './slices/transactionSlice'
import bank from './slices/bankSlice'
import notification from './slices/notificationSlice'

const rootReducer = {
    navbar,
    layout,
    auth,
    users,
    bank,
    wallet,
    transaction,
    contents,
    contact,
    brand,
    banner,
    category,
    voucher,
    size,
    products,
    calenderProduct,
    orders,
    setting,
    news,
    raffle,
    featureProducts,
    notification
}

export default rootReducer
