import { createSlice } from "@reduxjs/toolkit"

export const walletSlice = createSlice({
    name: "wallet",
    initialState: {
        walletList: [],
        isFreshData: false,
        totalEntry: 0,
    }, 
    reducers: {
        setWalletList: (state, action) => {
            state.walletList = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
    }
}) 

export const {
    setWalletList,
    setTotalEntry,
} = walletSlice.actions

export const selectWalletList = (state) => state.wallet.walletList
export const selectTotalEntry = (state) => state.wallet.totalEntry

export default walletSlice.reducer
