import { createSlice } from "@reduxjs/toolkit"

export const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    orderList: [],
    userList: [],
    exportList: [],
    orderDetail: {},
    isFreshData: false,
    totalEntry: 0,
    filterTerm: ''
  }, 
  reducers: {
    setOrderList: (state, action) => {
      state.orderList = action.payload
    },
    setUserList: (state, action) => {
      state.userList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.orderDetail = action.payload
    }
  }
})

export const {
  setOrderList,
  setUserList,
  setExportList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = ordersSlice.actions

export const selectOrderList = (state) => state.orders.orderList
export const selectUserList = (state) => state.orders.userList
export const selectExportList = (state) => state.orders.exportList
export const selectFilterTerm = (state) => state.orders.filterTerm
export const selectTotalEntry = (state) => state.orders.totalEntry
export const selectOrderDetail = (state) => state.orders.orderDetail

export default ordersSlice.reducer
