import { createSlice } from "@reduxjs/toolkit"

export const productSlice = createSlice({
  name: "brand",
  initialState: {
    productList: [],
    brandList: [],
    categoryList: [],
    exportList: [],
    productDetail: {},
    isFreshData: false,
    totalEntry: 0,
    filterTerm: ''
  },
  reducers: {
    setProductList: (state, action) => {
      state.productList = action.payload
    },
    setBrandList: (state, action) => {
      state.brandList = action.payload
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.productDetail = action.payload
    }
  }
})

export const {
  setProductList,
  setBrandList,
  setCategoryList,
  setExportList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = productSlice.actions

export const selectProductList = (state) => state.products.productList
export const selectBrandList = (state) => state.products.brandList
export const selectCategoryList = (state) => state.products.categoryList
export const selectExportList = (state) => state.products.exportList
export const selectFilterTerm = (state) => state.products.filterTerm
export const selectTotalEntry = (state) => state.products.totalEntry
export const selectProductDetail = (state) => state.products.productDetail

export default productSlice.reducer
