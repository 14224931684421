import { createSlice } from "@reduxjs/toolkit"

export const settingSlice = createSlice({
  name: "setting",
  initialState: {
    settingList: [],
    exportList: [],
    settingDetail: {},
    isFreshData: false,
    totalEntry: 0,
    filterTerm: ''
  },  
  reducers: {
    setSettingList: (state, action) => {
      state.settingList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.settingDetail = action.payload
    }
  }
})

export const {
  setSettingList,
  setExportList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = settingSlice.actions

export const selectSettingList = (state) => state.setting.settingList
export const selectExportList = (state) => state.setting.exportList
export const selectFilterTerm = (state) => state.setting.filterTerm
export const selectTotalEntry = (state) => state.setting.totalEntry
export const selectSettingDetail = (state) => state.setting.settingDetail

export default settingSlice.reducer
