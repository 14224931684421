import { createSlice } from "@reduxjs/toolkit"

export const reasonSlice = createSlice({
    name: "reason",
    initialState: {
        reasonList: [],
        contactList: [],
        isFreshData: false,
        totalEntry: 0,
    },
    reducers: {
        setReasonList: (state, action) => {
            state.reasonList = action.payload
        },
        setContactList: (state, action) => {
            state.contactList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
    }
})

export const {
    setReasonList,
    setContactList,
    setFreshList,
    setTotalEntry,
} = reasonSlice.actions

export const selectReasonList = (state) => state.contact.reasonList
export const selectContactList = (state) => state.contact.contactList
export const selectFreshData = (state) => state.contact.isFreshData
export const selectTotalEntry = (state) => state.contact.totalEntry

export default reasonSlice.reducer
